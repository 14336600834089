import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { MainEventsContainer } from "../../MainEvents/styles";
import {connect} from "react-redux";
import { getSurveyDetails } from "../../../redux/actions";
import { fetchApi } from "../../../utils/methods";
import CustomSurveyPopUp from "../SurveyLibrary/CustomSurveyPopUp";
import CompletedSurveyCard from "./CompletedSurveyCard";
import { FetchCompletedSurveys } from "../../../redux/constants/apiConstants";
import SkeletonLoder from "../../common/skeletonLoder";


const CompletedSurveys = (props) => {
  const {  getSurveyDetails, userPermissions, role, companyInfo } = props;
  //   const{state} = history.location

  const[completedSurveys,setCompletedSurveys]= useState([])
  const[isCustomSurvey, setisCustomSurvey] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const componentMounted = useRef(true); // (3) compo


  useEffect(() => {

    getSurveys();

    return () => {
      // This code runs when component is unmounted
      componentMounted.current = false; // (4) set it to false when we leave the page
    };
  }, []);

  const getSurveys = async() => {
    window.console.log("companyInfo",companyInfo)
    setIsLoading(true);
    try {
      const res = await fetchApi(FetchCompletedSurveys + companyInfo.id,"GET");

      if(res?.data?.message){
        toast.error(res.data.message)
      }else{
        setCompletedSurveys(res.data);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  };

  


  const Cards = (data, type)=>
    (
      <div
        style={{
          display: data.length > 0 && "flex",
          width: "100%",
          flexWrap: "wrap",
          margin:"unset"
        }}
      >
        {data && data.length > 0 ? (
          data.map((survey, index) => (


            <CompletedSurveyCard role ={role} survey={survey} key={index} getSurveyDetails={getSurveyDetails} userPermissions={userPermissions} type={type} getSurveys={()=>getSurveys("draft")} companyInfo={companyInfo}/>
            
          ))
        ) : (
          <div>No data found</div>
        )}
      </div>
    
    )
  

  return (
    <React.Fragment>
      <MainEventsContainer marginTop="1">
        {isLoading ? (
          <div style={{ display: "flex", width: "100%", flexWrap: "wrap",margin:"unset" }}>
            {Array.from({ length: 20 }).map((_, index) => (
              <SkeletonLoder key={index} width={"395px"} height={"200px"} style={{margin:"0px 20px 20px 0px"}}/>
            ))}
          </div>
        ) : Cards(completedSurveys,"draft")} 

        {isCustomSurvey && <CustomSurveyPopUp showModal={isCustomSurvey} onClose={()=>setisCustomSurvey(false)}/>}
      </MainEventsContainer>
    </React.Fragment>
  );
};

CompletedSurveys.propTypes = {
  history: PropTypes.object,
  surveys: PropTypes.array.isRequired,
  getSurveyDetails: PropTypes.func.isRequired,
  userPermissions: PropTypes.array,
  companyInfo:PropTypes.object,
  role:PropTypes.string,
};

const mapStateToProps = (state) => ({
  isLoading: state.surveys.isLoading,
  surveys: state.surveys.surveyLibraries,
  userPermissions: state.profileData.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  getSurveyDetails: (surveyId, history, route) =>
    dispatch(getSurveyDetails(surveyId, history, route)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompletedSurveys)
);
