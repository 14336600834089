import React, { useState } from "react";
import {
  EventCard,
  NewChampionsCard,
  NewContentV2,
  NewVideoPlayer,
} from "../../MainEvents/RecommendEventVideos/styles";
// import Thumbnail from "../../Thumbnail";
import { ImageUrl } from "../../../utils/constants";
import { DownloadIconNew, /*duplicateIcon*/ } from "../../../utils/icons";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import HoverButton from "../../common/HoverButton";
import {
  // CreateSurveyFromTemplate,
  DeleteSurveyFromLibrary,
  DeleteSurveyFromSurvey,
  // DuplicateSurveyFromSurvey,
  // UpdateSurvey,
} from "../../../redux/constants/apiConstants";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../utils/config";
import DeleteSurveyPopUp from "../SurveyLibrary/DeleteSurveyPopUp";
import moment from "moment";
import { getSurveyReportData } from "../SurveyInsights/QuestionInsight";
import LazyImage from "../../common/LazyImage/LazyImage";
// import DeleteSurveyPopUp from ".././DeleteSurveyPopUp";

const CompletedSurveyCard = (props) => {
  const { history, /*userPermissions,*/ survey, type, getSurveys,companyInfo } = props;
  const [onHover, setOnHover] = useState({});
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  //   const launchSurveyLibrary = getPermissionStatus(
  //     "Launch survey library",
  //     userPermissions
  //   );
  // const editSurveyLibrary = getPermissionStatus(
  //   "Update survey library",
  //   userPermissions
  // );

  const onEnterMouse = (id) => {
    setOnHover(id);
  };

  const onLeaveMouse = () => {
    setOnHover(-1);
  };

  //   const UseTemplate = async (suvreyId) => {
  //     if (type === "draft") {
  //       history.push({
  //         pathname: `/company/surveys/launch-custom`,
  //         state: { type: type, surveyId: survey.id, step: 1, isSurveyEdit: true },
  //       });
  //     } else {
  //       const AuthToken = localStorage.getItem("AUTH_TOKEN");
  //       let url =
  //         BASE_URL +
  //         (type === "woliba" || type === "organization"
  //           ? CreateSurveyFromTemplate
  //           : DuplicateSurveyFromSurvey) +
  //         `/${suvreyId}`;
  
  //       await fetch(url, {
  //         method: "POST",
  //         headers: {
  //           authtoken: AuthToken,
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //         // body: JSON.stringify(surveyDetails),
  //       })
  //         .then((res) => {
  //           res
  //             .json()
  //             .then((data) => {
  //               if (res.status === 200 || res.status === 201) {
  //                 getSurveys();
  //                 // history.push("/company/surveys")
  //                 history.push({
  //                   pathname: "/company/surveys/launch-custom",
  //                   state: {
  //                     surveyId: data.survey.id,
  //                     step: 1,
  //                     isSurveyEdit: true,
  //                     type: "draft",
  //                   },
  //                 });
  //               } else {
  //                 toast.error(data.data.message);
  //               }
  //             })
  //             .catch((error) => {
  //               toast.error(error.message);
  //             });
  //         })
  //         .catch((error) => {
  //           toast.error(error.message);
  //         });
  //     }
  //   };

  // const DuplicateSurvey = async (suvreyId) => {
  //   const AuthToken = localStorage.getItem("AUTH_TOKEN");
  //   let url =
  //     BASE_URL +
  //     (type === "woliba" || type === "organization"
  //       ? CreateSurveyFromTemplate
  //       : DuplicateSurveyFromSurvey) +
  //     `/${suvreyId}`;

  //   await fetch(url, {
  //     method: "POST",
  //     headers: {
  //       authtoken: AuthToken,
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     // body: JSON.stringify(surveyDetails),
  //   })
  //     .then((res) => {
  //       res
  //         .json()
  //         .then((data) => {
  //           if (res.status === 200 || res.status === 201) {
  //             getSurveys();
  //             // history.push("/company/surveys")
  //             if (type === "woliba" || type === "organization") {
  //               EditSurvey(data.survey.id, data.survey.title);
  //             } else {
  //               history.push({
  //                 pathname: "/company/surveys/launch-custom",
  //                 state: {
  //                   surveyId: data.survey.id,
  //                   step: 1,
  //                   isSurveyEdit: true,
  //                   type: type,
  //                 },
  //               });
  //             }
  //           } else {
  //             toast.error(data.data.message);
  //           }
  //         })
  //         .catch((error) => {
  //           toast.error(error.message);
  //         });
  //     })
  //     .catch((error) => {
  //       toast.error(error.message);
  //     });
  // };

  // const EditSurvey = async (suvreyId, title) => {
  //   const AuthToken = localStorage.getItem("AUTH_TOKEN");
  //   let url = BASE_URL + UpdateSurvey + `/${suvreyId}`;
  //   const surveyDetails = {
  //     title: title + "(Copy)",
  //   };

  //   await fetch(url, {
  //     method: "PUT",
  //     headers: {
  //       authtoken: AuthToken,
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     body: JSON.stringify(surveyDetails),
  //   })
  //     .then((res) => {
  //       res
  //         .json()
  //         .then((data) => {
  //           if (res.status === 200 || res.status === 201) {
  //             history.push({
  //               pathname: "/company/surveys/launch-custom",
  //               state: {
  //                 surveyId: data.survey.id,
  //                 step: 1,
  //                 isSurveyEdit: true,
  //                 type: type,
  //               },
  //             });
  //           } else {
  //             toast.error(data.data.message);
  //           }
  //         })
  //         .catch((error) => {
  //           toast.error(error.message);
  //         });
  //     })
  //     .catch((error) => {
  //       toast.error(error.message);
  //     });
  // };

  const DeleteSurvey = async (suvreyId) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url =
      BASE_URL +
      (type === "woliba" || type === "organization"
        ? DeleteSurveyFromLibrary
        : DeleteSurveyFromSurvey) +
      `/${suvreyId}`;

    await fetch(url, {
      method: "DELETE",
      headers: {
        authtoken: AuthToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // body: JSON.stringify(surveyDetails),
    })
      .then((res) => {
        if (res.status === 204) {
          // history.push("/company/surveys");
          setIsDeletePopup(false);
          getSurveys();
          // window.location.reload();
          // history.push({pathname:history.location.pathname, state:{tab:""}});
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <React.Fragment>
      <NewChampionsCard
        grid={0}
        margin={"25px"}
        style={{ marginBottom: "25px", height: "100%" }}
      >
        <EventCard
          videoCSS={0}
          onMouseEnter={() => onEnterMouse(survey.id)}
          onMouseLeave={() => onLeaveMouse()}
          style={{
            marginBottom: "0px",
            minHeight: "385px",
          }}
        >
          <NewVideoPlayer>
            <LazyImage src={`${ImageUrl}/${survey.image}`} alt={survey.name} fallbackImage={ImageUrl + "/admin-survey/survey_backdrop.png"}/>
            {/* <img src={`${ImageUrl}/${survey.image}`} alt={survey.survey_name} style={{width:'295px', height:'190px', borderRadius:'6px 6px 0px 0px'}}/> */}
          </NewVideoPlayer>
          {/* <div style={{position:"absolute", top:"20px", left:"25px", background:"white",borderRadius:"3px", height:"30px", padding:"5px 10px",textTransform:"capitalize"}}><span style={{color: "#005C87",fontFamily: "Rubik",fontSize: "14px",fontWeight: 400,lineHeight: "20px"}}>{"Completed"}</span></div> */}
          {onHover === survey.id && (
            <div
              className="educateImageBackground"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                className=""
                style={{
                  fontSize: "18px",
                  background: "white",
                  padding: "9px",
                  borderRadius: "3px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  width: "200px",
                  marginBottom: "15px",
                  color: "#005C87",
                  lineHeight: "20px",
                  fontFamily: "Rubik-Medium",
                }}
                onClick={() =>
                  history.push({pathname:`/company/survey-analytics/${survey.id}`,state:{page:"completed"}})
                }
              >
                View Insight{" "}
              </div>
              {/* {launchSurveyLibrary && (
                <div
                  className="getStarted"
                  style={{
                    background: "#005C87",
                    width: "200px",
                    borderRadius: "3px",
                  }}
                  onClick={() => UseTemplate(survey.id)}
                >
                  {t("Use Template")}
                </div>
              )} */}
            </div>
          )}

          <NewContentV2 newPadding={'newPadding'} style={{minHeight:'auto',flexDirection:"column"}}>
            <div style={{display:"flex",justifyContent:"space-between", alignItems:"center"}}>
              <div title={survey?.title} style={{textOverflow: "ellipsis",whiteSpace: "nowrap",overflow: "hidden", color:"rgba(0, 92, 135, 1)",fontFamily:"Rubik-Medium", fontSize:"18px",lineHeight:"24px",width:"100%"}}>{survey?.title}</div>
              {/* <div style={{color:"rgba(0, 92, 135, 0.6)", fontFamily: "Rubik-Regular",lineHeight:'20px',fontSize:'16px',display:'flex',flexDirection:'column', width:'100%', flexWrap:'wrap', alignItems:'end'}}>{survey.total_questions} Questions</div> */}
            </div>
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <div style={{color: "rgba(0, 92, 135, 0.60)",fontFamily: "Rubik-Regular",fontSize: "16px",fontWeight: 400,lineHeight: "20px", marginTop:'7px'}}>End Date - { moment(survey?.end_date).format('MMM Do, YYYY') || "-"}</div>
              <div style={{color: "rgba(0, 92, 135, 0.60)",fontFamily: "Rubik-Regular",fontSize: "16px",fontWeight: 400,lineHeight: "20px", marginTop:'7px'}}>{Math.round(survey?.survey_completed_count) || 0} Responses</div>
            </div>
          </NewContentV2>
          {/* <div
            style={{
              width: "100%",
              margin: "0px 15px 15px 15px",
              display: "-webkit-box",
              WebkitLineClamp: 4,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span
              style={{
                color: "#005C87",
                textAlign: "justify",
                fontFamily: "Rubik-Regular",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
              }}
            >
              {survey.description}
            </span>
          </div> */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginRight: "15px",
              alignItems: "center",
              textTransform: "capitalize",
              marginBottom: "16px",
            }}
          >
            {" "}
            <div style={{ display: "flex" }}>

              {/* <div>
                {editSurveyLibrary && (
                  <HoverButton
                    title={"Duplicate Survey"}
                    onClick={() => DuplicateSurvey(survey.survey_id)}
                    svgPath={duplicateIcon()}
                  />
                )}
              </div> */}
              <div>
                {/* <HoverButton title={"Download"} 
                  onClick={()=> getSurveyReportData(survey.survey_id)} 
                  svgPath={<PrintIcon fillOpacity={1} fil/>} fillOpacity={1} inActiveColor={"white"}/> */}
                <HoverButton onClick={()=> getSurveyReportData(survey.id, companyInfo.id)}  width={"40px"} height={"40px"} title={"Download"} fillOpacity={1} disabled={false} backgroundColor={"#f0f6f8"} backgroundColorActive={"#005c87"} activeColor={"#619ab5"} inActiveColor={"white"} svgPath={<DownloadIconNew/>} />
                  
              </div>

              {/* <div>
                <HoverButton
                  title={"Print Survey"}
                  //   onClick={() => setIsDeletePopup(true)}
                  svgPath={printIcon()}
                //   fillOpacity="1"
                />
              </div> */}
            </div>
            <div
              style={{
                background: "rgba(0, 92, 135, 0.05)",
                borderRadius: "3px",
                padding: "0px 10px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "rgba(0, 92, 135, 0.60)",
                  fontFamily: "Rubik-Regular",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                {survey?.category_name}
              </span>
            </div>
          </div>
        </EventCard>
      </NewChampionsCard>

      {isDeletePopup && (
        <DeleteSurveyPopUp
          showModal={isDeletePopup}
          onClose={() => setIsDeletePopup(false)}
          onYesClick={() => DeleteSurvey(survey.id)}
        />
      )}
    </React.Fragment>
  );
};

CompletedSurveyCard.propTypes = {
  history: PropTypes.object,
  surveys: PropTypes.array.isRequired,
  getSurveyDetails: PropTypes.func.isRequired,
  userPermissions: PropTypes.array,
  survey: PropTypes.object,
  type: PropTypes.bool,
  getSurveys: PropTypes.func,
  role: PropTypes.string,
  companyInfo: PropTypes.object,
};

export default withRouter(CompletedSurveyCard);
